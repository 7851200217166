<template>
  <div class="container"  v-loading="loading"
       element-loading-text="上传中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="page-title">图片上传</div>
    <div class="date-box" style="padding-left: 12px;margin-top: 10px;">
      <el-upload
        accept="image/*"
        action="#"
        :http-request="upload"
        :show-file-list="false"
        >
        <el-button icon="el-icon-upload2" type="primary">上传图片</el-button>
      </el-upload>
    </div>
    <div class="content">
      <el-table
          :data="tableData"
          stripe
          v-loading="listLoading"
        >
          <el-table-column
            label="上传档案文件"
            width="300">
            <template slot-scope="scope">
              <el-upload
                :class="{'hide-add': scope.row.imgList && scope.row.imgList.length > 0}"
                action="#"
                list-type="picture-card"
                :http-request="upload"
                :limit="1"
                :file-list="scope.row.imgList"
                accept="image/*"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url" alt=""
                  >
                  <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleDownload(file)"
                  >
                    <i class="el-icon-download"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
                </div>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </template>
          </el-table-column>
          <el-table-column
            label="大类"
            width="200">
            <template slot-scope="scope">
              <el-select filterable @change="((id)=>{return changeBigType(id, scope.$index)})" v-model="scope.row.firstName" placeholder="请选择" style="width: 132px;">
                <el-option
                  v-for="item in bigTypes"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            label="小类"
            width="200">
            <template slot-scope="scope">
              <el-select filterable @change="((id)=>{return changeSmallType(id, scope.$index)})" v-model="scope.row.secondName" placeholder="请选择" style="width: 132px;">
                <el-option
                  v-for="item in scope.row.smallTypes"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            label="名称"
            width="200">
            <template slot-scope="scope">
              <el-select filterable allow-create v-model="scope.row.lastName" placeholder="请选择" style="width: 132px;">
                <el-option
                  v-for="item in scope.row.foodNames"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="200">
            <template slot-scope="scope">
              <el-button @click="onSubmit(scope)" type="primary" size="small">上传档案</el-button>
              <el-button @click="deleteImg(scope.row.id)" type="danger" size="small">删除此项</el-button>
            </template>
          </el-table-column>
        </el-table>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      :page-sizes="[5, 10, 20, 40]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      :total="total"
    ></el-pagination>
 </div>
</template>
<script>
  export default {
    data() {
      return {
        tableData: [],
        value: '',
        total: 0,
        pageSize: 10,
        currentPage:1,
        listLoading: false,
        type: '',
        id: '',
        bigTypes: [],  //大类
        smallTypes: [],
        foodNames: [],
        dialogImageUrl: '',
        dialogVisible: false,
        disabled: false,
        loading: false,
        baseURL: ''
      };
    },
    activated () {
      this.baseURL = window.baseURL;
      this.queryOrderList(this.currentPage,this.pageSize);
      this.queryBigTypes(); //查询大类
    },
    methods: {
      queryOrderList(currentPage,pageSize){
        this.listLoading = true;
        this.$http.post(`/pic/findImgByPage/${currentPage}/${pageSize}`, {}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            this.listLoading = false;
            console.log('res====',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.tableData = res.data.data.list;
                this.tableData.forEach(item => {
                  item.imgList.forEach(value => {
                    value.url =  this.baseURL + '/images'+ value.imgSmallUrl
                      }
                    )
                  })
                this.total = res.data.data.total;
              console.log('this.taleData', this.tableData)
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      queryBigTypes(){
        this.$http.post(`/menuName/findFirstName`, {}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====queryOptions',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.bigTypes = res.data.data;
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      handleRemove(file) {
        console.log(file);
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = this.baseURL + '/images'+ file.imgUrl;
        this.dialogVisible = true;
      },
      handleDownload(file) {
        console.log(file);
      },
      handleSizeChange: function (size) {
        this.pageSize = size;
        console.log(this.pageSize);  //每页下拉显示数据
        this.queryOrderList(this.currentPage,this.pageSize);
      },
      handleCurrentChange: function(currentPage){
        this.currentPage = currentPage;
        console.log(this.currentPage);  //点击第几页
        this.queryOrderList(this.currentPage,this.pageSize);
      },
      changeBigType(id,index){
        console.log('changeBigType=======',id,index)
        const data = {
          firstId: id
        }
        this.$http.post("/menuName/findSecondName", data, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              // this.tableData[index].smallTypes = res.data.data;
              this.$set(this.tableData[index],'smallTypes',res.data.data)
              this.tableData[index].secondName = '';
              this.tableData[index].lastName = '';
              console.log(this.tableData,'this.tableData')
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      changeSmallType(id,index){
        console.log('changeSmallType=======',id)
        const data = {
          secondId: id
        }
        this.$http.post("/menuName/findLastName", data, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.$set(this.tableData[index],'foodNames',res.data.data)
              this.tableData[index].lastName = '';
              // this.foodNames = res.data.data;
            } else {
            }
            this.$message.error(res.data.data)
          });
      },
      upload(fileObj) {
        this.loading = true;
        let that = this;
        console.log('fileObj',fileObj);
        const formData = new FormData();
        formData.append('file', fileObj.file)
        formData.append('type', fileObj.file.type)
        console.log('upload',formData)
        let xhr = new XMLHttpRequest();
        xhr.onload =  (event) => {
          const res = JSON.parse(`${xhr.responseText}`)
          console.log(`这是onload函数请求的文本:${xhr.responseText}`);
          console.log(`这是onload函数请求的文本:${this.responseText}`);
          console.log('res====',res)
          if (res.code === 'ok') {
            console.log('resdatadata====',res.data)
            // this.$message.success('策划记录更新成功')
            this.queryOrderList(this.currentPage,this.pageSize);
            // that.listLoading = false;
            that.loading = false;
            // this.$router.push({ path: "/layout" });
          } else {
            that.$message.error(res.data)
          }
        }
        xhr.open('POST', this.baseURL + '/pic/batch/upload', true);
        xhr.setRequestHeader('token',window.sessionStorage.manageToken)
        xhr.send(formData);
      },
      onSubmit(scope){//表单提交的事件
        const item = scope.row;
        const index = scope.$index;
        console.log('提交-------------',item)
        let data = {
          id: item.id,
          firstId:item.firstName,
          secondId:item.secondName,
        }
        console.log('this.tableData[index].foodNames',this.tableData[index].foodNames)
        let flag = null;
        if(this.tableData[index] && this.tableData[index].foodNames){
          flag = this.tableData[index].foodNames.find((value, index, array)=>{
            console.log(value.id,item.lastName)
            return item.lastName === value.id;
          })
        }
        console.log('flag',flag)
        flag ? data.lastId = item.lastName : data.lastName = item.lastName
        this.$http.post("/pic/editImg", data , { headers: {
            token: window.sessionStorage.manageToken,
          }})
          .then(res => {
            console.log('res====',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.$message.success('编辑菜谱成功')
              this.queryOrderList(this.currentPage,this.pageSize);
              // this.$router.push({ path: "/layout" });
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      deleteImg(id){
        this.$confirm('确定要删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post("/pic/deleteImg", { id } , { headers: {
              token: window.sessionStorage.manageToken,
            }})
            .then(res => {
              console.log('res====',res)
              if (res.data.code === 'ok') {
                console.log('resdatadata====',res.data.data)
                this.$message.success('删除成功');
                this.currentPage = 1;
                this.queryOrderList(this.currentPage,this.pageSize);
                // this.$router.push({ path: "/layout" });
              } else {
                this.$message.error(res.data.data)
              }
            });
        }).catch(() => {
        });
      },
    },
    watch: {

    },
    filters: {
    }
  };
</script>
<style lang="less" scoped>
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .content {
    flex: 1;
    margin-top: 10px;
    /*margin-bottom: 20px;*/
    overflow: auto;

  }
  .first-line {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  .label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /*text-align: right;*/
    font-size: 16px;
    color: #000000;
    padding-right: 10px;
  }
  .date-box {
    display: flex;
    align-items: center;
    padding-left: 12px;
  }
  .third-line {
    margin-left: 20px;
    margin-top: 10px;
  }
  /deep/ .cell {
    text-align: center;
    display: flex;
    justify-content: center;
    > div {
      display: flex;
    }
  }
  /deep/ .el-upload-list {
    display: flex;
    flex-wrap: wrap;
    flex:1;
  }
  /deep/ .el-upload--picture-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hide-add /deep/ .el-upload--picture-card{
    display:none;   /* 上传按钮隐藏 */
  }
</style>
